.table-head
  display flex
  width 100%
  height 50px
  z-index 1
  font-weight 500
  position sticky
  overflow visible
  background-color #fff
  min-width fit-content
  font-size 14px
  position sticky
  top 0

  &__extra
    flex 1
    display flex
    padding-right 14px
    align-items center
    justify-content flex-end

  &__column-extra
    margin-left 10px
    display flex
    align-items center
  
  &__draggable
    --scale 1
    transform scale(var(--scale));
    cursor move
