.resizer
  position: relative;

  &__handle
    top 0
    left 100%
    height 100%
    padding 0 6px
    position absolute
    cursor col-resize
    z-index 100

    &::before
      top 0
      left 0
      bottom 0
      width 1px
      content ""
      z-index 5
      display block
      background #bdbdbd
      position absolute

    &:hover::before,
    &_resizing::before
      top 0
      bottom 0
      background-color $accent_color
      box-shadow 0 0 0 1px $accent_color

    // &_resizing::after
    //   top 0
    //   left 0
    //   width 1px
    //   content ""
    //   z-index 1
    //   height 9999px
    //   position absolute
    //   background-color #ccc
