.data-view
  flex 1
  display flex
  overflow hidden
  flex-direction column

.no-results
  height 100%
  display flex
  flex-direction column
  justify-content center
  align-items center

  &__description
    font-size 16px
    text-align center
    color rgba(#000, 0.6)
