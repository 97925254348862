.columns__selector
  position absolute
  width 100%
  overflow hidden
  display flex
  justify-content flex-end
  top 0

.table
  width 100%
  height 100%
  display flex
  flex-direction column
  overflow auto
  background-color #fff

  &_fit
    width max-content

  &__sticky-header
    width 100%

  &__auto-size,
  &__virtual
    width auto

    ^[0]_fit &
      width fit-content

  &__virtual
    max-width: 100vw;
    min-width: 100vw;

  &__virtual > div
    min-width fit-content

  &__row-wrapper
    min-width fit-content
    position relative
    cursor pointer
    background-color #fff

    &_disabled,
    &_loading
      opacity 0.6
      pointer-events none

    &_even:not(&_selected)
      background-color #fafafa

    &_selected
      background-color #e3f1ff

    &:not(&_selected):hover
      background-color #f3f9ff

    &_highlighted:not(&_selected)::after
      top 0
      left 0
      width 100%
      height 100%
      content ""
      z-index 50
      position absolute
      pointer-events none
      box-shadow 0 0 0 1px rgba($accent_color, 0.8) inset

  &__cell
    flex 150 0 auto
    width fit-content
    min-width min-content
    max-width min-content
    padding 10px
    box-sizing border-box
    display flex
    align-items center
    position relative
    overflow hidden
    word-break break-word

  &__cell-content
    flex 0
    display flex
    white-space nowrap
    align-items center

    &_disabled
      opacity 0.6
      pointer-events none
