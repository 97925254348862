.data-view
  flex 1
  display flex
  overflow hidden
  flex-direction column

  &_loading
    opacity 0.6
    pointer-events none

  &_locked
    pointer-events none

  &__footer
    display flex
    padding 5px 8px
    min-height 36px
    align-items center
    justify-content flex-end
    background-color #fff
    border-top 1px solid rgba(#000, 0.1)

.no-results
  height 100%
  display flex
  flex-direction column
  justify-content center
  align-items center

  &__description
    font-size 16px
    text-align center
    color rgba(#000, 0.6)
